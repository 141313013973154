import React, { useState, useEffect, useRef } from 'react';
import {
  UserAgent,
  Registerer,
  Inviter,
  Invitation,
  RegistererOptions,
  UserAgentOptions,
  WebTransportOptions,
  InvitationAcceptOptions,
} from 'sip.js';

const Dialer = () => {
  const [callState, setCallState] = useState(null);
  const [userAgent, setUserAgent] = useState(null);
  const [session, setSession] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isMuted, setIsMuted] = useState(false);
  const remoteAudioRef = useRef(null);

  // SIP Configurations (update with your server details)
  const sipConfig = {
    uri: 'sip:1003@192.168.2.30',
    wsServers: 'wss://192.168.2.30:8089/ws', // Note: Use 'wss' for secure connection
    username: '1003',
    password: '1003',
    domain: '192.168.2.30',
  };

  useEffect(() => {
    initializeUserAgent();
    return () => {
      if (userAgent) {
        userAgent.stop();
      }
    };
  }, []);

  const initializeUserAgent = () => {
    const transportOptions: WebTransportOptions = {
      server: sipConfig.wsServers,
      connectionTimeout: 5, // Shorter timeout to quickly detect issues
      keepAliveInterval: 15, // Keep the connection alive
      traceSip: true, // Enable SIP trace for debugging
    };

    const userAgentOptions: UserAgentOptions = {
      uri: UserAgent.makeURI(`sip:${sipConfig.username}@${sipConfig.domain}`),
      transportOptions: transportOptions,
      authorizationUsername: sipConfig.username,
      authorizationPassword: sipConfig.password,
      sessionDescriptionHandlerFactoryOptions: {
        peerConnectionConfiguration: {
          iceServers: [{ urls: 'stun:stun.l.google.com:19302' }], // STUN server for NAT traversal
          bundlePolicy: 'balanced', // SDP bundle policy
        },
      },
    };

    const ua = new UserAgent(userAgentOptions);

    ua.delegate = {
      onInvite: handleIncomingCall,
      onTransportError: handleTransportError,
    };

    ua.start()
      .then(() => {
        const registererOptions: RegistererOptions = {
          expires: 60, // Registration expiry in seconds
        };
        const registerer = new Registerer(ua, registererOptions);
        registerer.register().catch((error) => {
          console.error('Registration error:', error);
        });
        setUserAgent(ua);
        console.log('User agent started and registered');
      })
      .catch((error) => {
        console.error('Error starting user agent:', error);
        handleTransportError(error);
      });
  };

  const handleTransportError = (error) => {
    console.error('Transport error:', error);
    // Implement retry logic here
    if (error && error.message.includes('certificate')) {
      console.warn('SSL error encountered. Attempting to reconnect...');
      setTimeout(() => {
        initializeUserAgent(); // Attempt to reconnect
      }, 5000); // Retry after 5 seconds
    }
  };

  const handleIncomingCall = (invitation: Invitation) => {
    console.log('Incoming call:', invitation);
    setSession(invitation);
    setCallState('incoming');
  };

  const startCall = () => {
    if (userAgent && phoneNumber) {
      const targetURI = UserAgent.makeURI(`sip:${phoneNumber}@${sipConfig.domain}`);
      const inviter = new Inviter(userAgent, targetURI);

      inviter.invite().then(() => {
        setSession(inviter);
        setCallState('calling');
      }).catch((error) => {
        console.error('Call error:', error);
      });
    }
  };

  const acceptCall = () => {
    if (session && session instanceof Invitation) {
      const acceptOptions: InvitationAcceptOptions = {
        sessionDescriptionHandlerOptions: {
          remote: {
            audio: remoteAudioRef.current,
          },
        },
      };
      session.accept(acceptOptions).then(() => {
        setCallState('in-call');
        session.stateChange.addListener((state) => {
          if (state === 'terminated') {
            setCallState(null);
            setSession(null);
          }
        });
      }).catch((error) => {
        console.error('Accept error:', error);
      });
    }
  };

  const hangupCall = () => {
    if (session) {
      session.terminate().then(() => {
        setCallState(null);
        setSession(null);
      }).catch((error) => {
        console.error('Hangup error:', error);
      });
    }
  };

  const muteUnmute = () => {
    if (session && session.sessionDescriptionHandler) {
      const pc = session.sessionDescriptionHandler.peerConnection;
      pc.getSenders().forEach((sender) => {
        if (sender.track.kind === 'audio') {
          sender.track.enabled = !sender.track.enabled;
          setIsMuted(!sender.track.enabled);
        }
      });
    }
  };

  return (
    <div>
      <div>Dialer</div>
      <audio ref={remoteAudioRef} id="remoteAudio" autoPlay></audio>
      {callState === 'incoming' && (
        <div>
          <div>Incoming call...</div>
          <button onClick={acceptCall}>Accept</button>
          <button onClick={hangupCall}>Reject</button>
        </div>
      )}
      {callState === 'in-call' && (
        <div>
          <div>In call...</div>
          <button onClick={hangupCall}>End Call</button>
          <button onClick={muteUnmute}>{isMuted ? 'Unmute' : 'Mute'}</button>
        </div>
      )}
      <div>
        <input
          type="text"
          placeholder="Enter number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <button onClick={startCall} disabled={!phoneNumber}>Call</button>
      </div>
    </div>
  );
};

export default Dialer;
